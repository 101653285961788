import React from 'react';
import Layout from '../components/Layout';

function SubProcessors() {
  return (
    <Layout>
      <div className="fit-width page-legal pt-5">
        <div className="banner -legal">
          <div className="chip">Fibonacci</div>
          <h1>Sub Processors</h1>
        </div>
        <p className="privacy-policy">To support delivery of our Services, Fibonacci may transfer data to its Sub-Processors.</p>
        <br />
        <p style={{ marginBottom: 24 }} className="privacy-policy">We use the third parties listed below to host our customer data and provide specific functionality within the service</p>
        <table className="privacy-policy">
          <thead>
            <tr>
              <th>Sub-Processor Name</th>
              <th>Type of Service</th>
              <th>Data Location and Security</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Amazon Web Services, Inc.</td>
              <td>Cloud hosting and storage</td>
              <td>Customisable for Tenant Owner</td>
            </tr>
            <tr>
              <td>SendGrid Inc.</td>
              <td>Email notification service</td>
              <td>USA</td>
            </tr>
            <tr>
              <td>Intercom</td>
              <td>Support ticketing service</td>
              <td>USA</td>
            </tr>
            <tr>
              <td>Sentry</td>
              <td>Error Monitoring</td>
              <td>USA</td>
            </tr>
            <tr>
              <td>Auth0</td>
              <td>Identity and Authorisation</td>
              <td>Customisable for Tenant Owner</td>
            </tr>
          </tbody>
        </table>
        <p style={{ marginTop: 24, marginBottom: 32 }} className="privacy-policy">The list of Sub-Processors we engage may change from time to time. Please check back frequently for updates.</p>
      </div>
    </Layout>
  );
}

export default SubProcessors;
